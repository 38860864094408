@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Poppins:wght@500;600&family=Raleway:wght@600&display=swap');

.avatar-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    width: 100%;
    color: #27B147;
    font-family: 'Montserrat', sans-serif;
}

.avatar-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-avatar .ant-avatar-string {
    left: -5%;
    position: relative;
}
