@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Poppins:wght@500;600&family=Raleway:wght@600&display=swap');

.accountContent-left {
    margin-left: 8px;
    margin-right: 4px;
    background-color: white;
    height: 100%;
    border-radius: 10px;
}

.accountContent-right {
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.accountContent-right-top {
    margin-left: 4px;
    margin-right: 8px;
    background-color: white;
    height: 10%;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    display: flex;
    align-items: center;
}

.accountContent-right-bottom {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 4px;
    margin-right: 8px;
    background-color: white;
    height: 90%;
    flex: 1;
    border-radius: 10px;
}

.account-menu {
    display: flex;
    padding-left: 8px;
    font-size: 15px;
    height: 50px;
    font-family: 'Montserrat', sans-serif;
}

.green-text {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    font-size: 15px;
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    color: #81B338;
}

.account-menu:hover {
    cursor: pointer;
    color: #81B338;
}

.account-menu-text {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.active-button, .deactive-button {
    width: 90px;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.active-button {
    border-color: green;
    color: green;
}

.active-button:hover {
    border: transparent !important;
    border-color: green  !important;
    background-color: green !important;
    color: white  !important;
}

.deactive-button {
    border-color: red;
    color: red;
}

.deactive-button:hover {
    border: transparent !important;
    border-color: red  !important;
    background-color: red !important;
    color: white  !important;
}

.table-odd-row {
    background-color: white;
}

.table-even-row {
    background-color: #F2F2F2;
}

.ant-table-thead th {
    background-color: #cdcdcd !important;
}

.input-rows {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 25px;
}

.input-address {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    height: 100%;
}

.input-rows-items-left, .input-rows-items-right {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-rows-items-left {
    padding-left: 50px;
    padding-right: 25px;
}

.input-rows-items-right {
    padding-left: 25px;
    padding-right: 50px;
}

.input-text {
    color: black;
    padding-bottom: 5px;
}

.input-text:hover {
    cursor: default;
    color: black;
    text-decoration: none;
}

.save-details-change-button {
    border: transparent;
    border-color: green;
    background-color: green;
    color: white;
}

.save-details-change-button:hover {
    background-color: rgb(0, 100, 0) !important;
    color: white !important;
}

.modal-title-container {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 20px;
}

.modal-title-text {
    height: 100%;
    vertical-align: middle;
}

.warning-modal-yes-button {
    width: 80px;
    background-color: green;
}

.warning-modal-yes-button:hover {
    cursor: pointer;
    background-color: rgb(0, 100, 0) !important;
}

.warning-modal-no-button {
    width: 80px;
}

.warning-modal-no-button:hover {
    cursor: pointer;
    color: green !important;
    border: 1px solid green !important;
}

.PhoneInput-account-normal {
    width: 100%;
    font-size: 20px;
    border-radius: 6px;
    border: 1px solid transparent;
}

.PhoneInput-account-error {
    width: 100%;
    font-size: 20px;
    border-radius: 6px;
    border: 1px solid red;
    transition-delay: 0.2s;
}

.PhoneInput-account-normal:focus {
    width: 100%;
    font-size: 20px;
    border-color: transparent;
}

.PhoneInput-account-input {
    width: 100%;
    height: 41px;
}

.PhoneInput-account-normal::placeholder {
    color: #BFBFBF;
    font-size: 20px;
}

.react-tel-input .form-control {
    width: 100%;
    border: 1px solid #CACACA;
    border-radius: 5px;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
}

.PhoneInput-account-normal:hover {
    border-color: transparent; 
}

.PhoneInput-account-normal:focus {
    width: 100%;
    border-color: transparent;
    pointer-events: none;
}

.react-tel-input .form-control:focus {
    width: 100%;
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    pointer-events: none;
}

.react-tel-input .form-control:hover {
    border-color: #4096ff; 
}

.history-titles {
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: large;
}

.loading {
    width: 30px;
    height: 30px;
    border: 2px solid #000;
    border-top-color: transparent;
    border-radius: 100%;
  
    animation: circle infinite 0.75s linear;
}

.imageContent-val {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center; 
    height: 100%; 
    background-color: white;
    border-radius: 10px;
    padding-bottom: 15px; 
}

.val-table {
    font-size: 12px;
}

@keyframes circle {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Select hover border color */
.ant-select:hover .ant-select-selector {
    border-color: #81B338 !important;
}

/* Select focus border color and box shadow */
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
    border-color: #81B338 !important;
    box-shadow: 0 0 0 2px rgba(129, 179, 56, 0.2) !important;
}

/* Dropdown menu selected item background color */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(129, 179, 56, 0.2) !important;
}

/* Dropdown menu hover item background color */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(129, 179, 56, 0.1) !important;
}


/* Close icon color in tags */
.ant-select-selection-item .anticon-close {
    color: #81B338 !important;
}

.history-statistics-table .ant-table {
    font-size: 12px;
}

.history-statistics-table .ant-table-thead > tr > th {
    font-size: 12px;
    font-weight: bold;
}

.history-statistics-table .ant-table-tbody > tr > td {
    font-size: 12px;
}
