@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Poppins:wght@500;600&family=Raleway:wght@600&display=swap');


html, body { 
    height: 100%;
}

body { margin: 0; padding: 0; }

.footer {
    width: 100%; 
    height: 60px; 
    left: 0;
    text-align: center; 
    font-size: 18px;
    color: #fff;
}

.header {
  width: 100%; 
  height: 60px; 
  position: absolute; 
  left: 0;
  text-align: center; 
  font-size: 18px;
  color: #fff;
  line-height: 60px;
  font-family: 'Raleway', sans-serif;
}

.project-introduction {
    width: 100%; 
    height: 800px;
    text-align: center; 
    background: #ACDDC0;
}

.project-introduction iframe {
    width: 75%;
    height: 75%;
    padding-top: 20px;
}

.cover {
    width: 100%; 
}

.cover-img {
    height: 600px;
    text-align: center; 
    background-image: url('../img/cover.jpg');
    background-size: 115%;
    background-position: -100px;
    background-repeat: no-repeat;
}

.header { 
    background-color: rgb(255, 255, 255); 
    top: 0; 
    text-align: left;
    font-size: 28px;
    color: #81B338;
    width: 100%;
}

.logo, .logo-home {
    z-index: 1100;
    width: 200px;
    height: 100%;
    margin-top: 5px;
    margin-left: 3%;
}

.logo-home {
    user-select: none;
}

.logo:hover {
    cursor: pointer;
}

.cover-description-title, .cover-description-content {
    color: #ffffff;
}

.cover-description-title {
    margin: 30px auto;
    text-align: center;
    width: 50%;
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.cover-description-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-align: center;
  margin: 30px auto;
  text-align: center;
  width: 50%;
}

.learn-more {
    background-color: #a5a5a5;
    height: 80px;
    text-align: center;
}

.learn-more span {
  vertical-align: middle;
  line-height: 75px;
  margin-right: 10px;
  color: #000;
}

.learn-more-content {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.footer { 
    background-color: #333333; 
    height: 380px;
}

.footer-img-container {
  display: flex;
  padding-left: 10px;
}

.footer-img {
  height: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-title, .footer-contact-info, .footer-email, .footer-ventures {
  display: flex;
  margin-left: 30px;
}

.footer-title, .footer-contact-info {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.footer-title, .footer-contact-info {
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 25px;
  height: 30px;
}

.footer-title {
  margin-top: 6px;
  margin-bottom: 15px;
}

.footer-ventures a, .footer-email a {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.footer-email, .footer-ventures {
  margin-bottom: 5px;
}

.home {
    display: flex;
    /* height: 3040px; */
    flex-direction: column;
}

.intro-title, .intro-content {
  align-items: center;
  color: #01792F;
}

.intro-title {
    height: 50px;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.intro-content {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.image-introduction {
  width: 100%; 
  height: 800px;
  bottom: 80px;
  padding: 40px 0; 
  text-align: center; 
  background: #B2E7D6;
  display: flex;
}

.image-introduction-text {
    margin-top: 300px;
    width: 500px;
    margin-left: 25px;
}

.image-introduction-text-title {
  font-size: 30px;
  color: #0B7189;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.image-introduction-text-content {
  font-size: 25px;
  margin-top: 10px;
  color: #0B7189;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.introduction-image-container {
  display: flex;
  flex-direction: column;
}

.golden-shouldered-parrots-container {
  margin-top: -80px;
  z-index: 1100;
}

.golden-shouldered-parrot-with-tree, .McNemo-fish-with-coral {
  width: 380px;
  border-radius: 35px;
}

.McNemo-fish-with-coral {
  margin-top: -80px;
}

.McNemo-fish-with-hand {
  width: 280px;
  border-radius: 35px;
  margin-left: 500px;
  margin-top: -80px;
}

.golden-shouldered-parrot1, .golden-shouldered-parrot2 {
  width: 110px;
  border-radius: 35px;
}

.golden-shouldered-parrot1 {
  margin-left: -80px;
}

.golden-shouldered-parrot2 {
  margin-left: 15px;
}

.golden-shouldered-parrot-with-tree-label, .golden-shouldered-parrot-with-coral-label {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.GP-image-container {
    position: relative;
    width: 100%;
}

.McNemo-image-container {
  position: relative;
  width: 100%;
  margin-left: -150px;
  margin-top: 40px;
}

.golden-shouldered-parrot-with-tree-label {
  font-size: 25px;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.golden-shouldered-parrot-with-coral-label {
  font-size: 25px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -180%);
}

.step-page {
  width: 100%; 
  height: 760px;
  bottom: 80px;
  padding: 20px 0; 
  text-align: center; 
  background: #FFF19F;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.step-page-container {
  display: flex;
  margin-top: 50px;
  margin-left: 50px
}

.step-page-container span {
  margin-left: 60px;
  font-size: 70px;
  color: #ffffff;
  margin-top: 25px;
  margin-right: 60px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.upload-img, .train-img, .analysis-img {
  width: 150px;
}

.step-page-description-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: #016426;
}

.step-page-title, .step-page-content, .step-page-slogan1, .step-page-slogan2 {
  text-align: left;
}

.step-page-title {
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.step-page-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.step-page-slogan1, .step-page-slogan2 {
  font-size: 30px;
  color: #016426;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.step-page-slogan1 {
  margin-top: 10px;
  margin-left: 300px;
}

.step-page-slogan2 {
  margin-top: 55px;
  margin-left: 100px;
}
