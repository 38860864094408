@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Poppins:wght@500;600&family=Raleway:wght@600&display=swap');

.labelling-content {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.labelling-warning {
    display: flex;
    flex-direction: column; 
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffcdcc;
    border-radius: 5px;
    font-size: 14px;
  }

.labelling-color {
    font-family: 'Montserrat', sans-serif;
    display: 'flex';
}

.image-container.selected {
    border: 4px solid #ffa2a1;
}


.labelled-marker, .labelled-marker-marked, .labelled-marker-mark-failed {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.labelled-marker-marked {
    background-color: #0186F6;
    color: white;
}

.labelled-marker-mark-failed {
    background-color: orange;
    color: white;
}

.labelled-marker-validate {
    position: absolute;
    top: 10px;
    right: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 166, 0, 0.83);
}

.age_examples {
    font-family: 'Montserrat', sans-serif;
}

.age_examples p{
    width: 50px;
}

.age_examples_img {
    margin-left: 10px;
}

.sider-left {
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 4px;
    background-color: #e0e0e0;
}

.sider-right {
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 4px;
    padding-right: 8px;
    background-color: #e0e0e0;
}

.imageContent {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    height: 100%; 
    background-color: white;
    border-radius: 10px;
}

.sex-age-examples-icon {
    color: #ce9d1d;
}

.sex-age-examples-icon:hover {
    color: green;
}
