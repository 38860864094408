.search {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-weight: 200;
}

.search-input:hover {
    border-color: #81B338 !important;
}

.reset-button:hover {
    color: green !important;
    border-color: green !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: green !important;
    border-color: green;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: green !important;
}